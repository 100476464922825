import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { centerNewsPageSize } from "@/utils/const";
import { sleep, startLoading, endLoading } from "@/utils/function"; 
import API from '@/api/teacher/center/webNewsList';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "other",
    components: {},
    data() {
        return {
            // 搜索值
            input: "",
            // 当前页码
            page: 1,
            // 每页显示数据数量
            pageSize: centerNewsPageSize,
            // 数据总条数
            dataTotal: 500,
            // 表格数据
            tableData: [
                {
                    id: 149,
                    title: "醇系物(大类6学时)",
                    time: "2019-03-23 09:12:32",
                    folderid: 18
                },
                {
                    id: 166,
                    title: "阿司匹林-液相(4学时)",
                    time: "2019-04-07 15:30:25",
                    folderid: 18
                }
            ],
            // dialog 显示标识
            dialogVisible: false,
            // 项目id
            article_id: "",
            // 操作标识
            handleSign: "",
            // 添加分类名称
            categoryName: "",
            // 弹窗标题
            dialogTitle: "",
            // 排序
            sort_no: 0,
            // 二维码链接
            qrcode: "",
            // 文章标题
            articleTitle: "",
            // 分类列表
            categoryList: "",
            //  添加文章分类id
            folderid: ""
        };
    },
    created() 
    {
        const self = this;
        self.input = this.$router.currentRoute.query.search;
        self.page = Number(this.$router.currentRoute.query.page);
        self.getData();
        // self.getCategoryList();
    },
    methods: 
    {
        /**
         * @name: 新闻分页
         * @author: camellia
         * @date: 2021-03-15
         */
        handlePageChange(val) {
            const self = this;
            self.page = val;
            self.getData();
        },
        /**
         * @name: 置顶操作
         * @author: camellia
         * @date: 2021-03-25
         */
        handleTop(scope) 
        {
            const self = this;
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            let data = {
                id: scope.id,
                is_top: scope.is_top
            };
            // 请求数据
            API.handleTop(data)
                .then(result => {
                    self.getData();
                    nprogressClose();
                    self.$Loading.hide();
                    this.$Tips({
                        // 消息提示内容
                        message:result.msg,
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'success',
                        displayTime:1500
                    })
                })
                .catch(err => {});
        },

        /**
         * @name: 删除/修改/添加弹窗
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        handleOpen(sign, title = "", index = "", row = "") {
            const self = this;
            self.handleSign = sign;
            self.dialogTitle = title;

            if (sign == "is_delete") 
            {
                self.dialogVisible = true;
                self.articleTitle = row.title;
                self.article_id = row.id;
            } 
            else if (sign == "is_add") 
            {
                self.dialogVisible = true;
            } 
            else if (sign == "is_update") 
            {
                self.article_id = row.id;
                self.$router.push({
                    path: "/teacher/center/webNewsUpdate",
                    query: {
                        article_id: self.article_id
                    }
                });
            }
        },

        /**
         * @name: 弹窗确定键点击事件
         * @author: camellia
         * @date: 2021-01-11 10:25:50
         */
        dialogFix() {
            const self = this;
            let data = {
                article_id: self.article_id,
                categoryName: self.categoryName
            };

            if (self.handleSign == "is_delete") 
            {
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.deleteNews(data)
                    .then(result => {
                        self.getData();
                        self.dialogVisible = false;
                        nprogressClose();
                        self.$Loading.hide();
                        this.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                    });
            } 
            else if (self.handleSign == "is_add") 
            {
                let data = {
                    title: self.categoryName
                };
                if (self.categoryName == "") {
                    // Message.warning("请填写新闻标题");
                    this.$Tips({
                        // 消息提示内容
                        message:"请填写新闻标题",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                    return;
                }
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.saveNews(data)
                    .then(result => {
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                        self.article_id = result.data.id;
                        self.$router.push({
                            path: "/teacher/center/webNewsUpdate",
                            query: {
                                article_id: self.article_id
                            }
                        });
                    })
                    .catch(err => {
                        nprogressClose();
                        self.dialogVisible = false;
                    });
            }
        },

        /**
         * @name: 点击分页
         * @author: camellia
         * @date: 2020-12-29 16:38:39
         */
        handleCurrentChange(val) {
            const self = this;
            self.page = val;
            self.getData();
            // 路由传参
            self.$router.push({
                path: "/teacher/article/articleList",
                query: {
                    search: self.input,
                    page: self.page
                }
            });
        },
        /**
         * @name:修改排序
         * @author: camellia
         * @date: 2021-01-15 10:07:15
         */
        sortNoUpdate(param) {
            const self = this;
            let url = "/index.php/center/updateNewsSort";
            let data = {
                id: param.id,
                sort_no: param.sort_no
            };
            // 请求数据
            API.updateNewsSort(data)
                .then(result => {
                    self.getData();
                })
                .catch(err => {});
        },

        /**
         * @name: 获取显示数据
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        getData() 
        {
            const self = this;
            let data = {
                page: self.page,
                search: self.input
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.getNewsList()
                .then(result => {
                    self.tableData = result.newsList;
                    self.dataTotal = result.newsNum;
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(err => {});
        }
    }
};
