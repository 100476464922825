import { request } from "@/request/http";

export default {
  handleTop: (data) => {
    return request("GET", `/index.php/center/handleTop`, data);
  },

  deleteNews: (data) => {
    return request("GET", `/index.php/center/deleteNews`, data);
  },

  saveNews: (data) => {
    return request("GET", `/index.php/center/saveNews`, data);
  },

  updateNewsSort: (data) => {
    return request("GET", `/index.php/center/updateNewsSort`, data);
  },

  getNewsList: (data) => {
    return request("GET", `/index.php/center/getNewsList`, data);
  },
}
